// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // baseUrl: 'http://13.233.108.108:4000',
  // baseUrl: 'http://3.111.247.225:4020',
  // baseUrl: 'http://3.111.247.225:4010',
  baseUrl: 'http://13.234.188.15:4010',
  // baseUrl: 'https://evpitstop.in/b154b2a5cbf2ab36',

  firebaseConfig: {
    apiKey: "AIzaSyCQUiBFNN3HAQLzhi88r-U-dCtUXuhwumI",
    authDomain: "evpitstopuser-7e287.firebaseapp.com",
    projectId: "evpitstopuser-7e287",
    storageBucket: "evpitstopuser-7e287.appspot.com",
    messagingSenderId: "570878735957",
    appId: "1:570878735957:web:df1fc0dbfe0a99b04886dc",
    measurementId: "G-NQB00DE21M"
  },
  firebasePublicKey: 'BHCY6vLaVIK_pNatjgaAiQXMDNMlB1zCHfCvbnfSFIxpFDdiTeBGIsmUuAMihcB0DI9JFLfr0BRcPdR4LKAIVXI'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
